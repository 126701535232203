<template>
  <LayoutSidebar :toolbar="false" :sidebar="false" class="bg-muted-200 dark:bg-muted-900 min-h-screen w-full">
    <template #logo>
      <NuxtLink
        to="/"
        class="text-muted-400 hover:text-primary-500 hover:bg-primary-500/20 flex size-12 items-center justify-center rounded-2xl transition-colors duration-300"
        @click.prevent="$router.back()"
      >
        <Icon name="lucide:arrow-left" class="size-5" />
      </NuxtLink>
    </template>

    <LayoutDefaultNavigation />

    <div class="pb-32 pt-24">
      <slot />
    </div>
  </LayoutSidebar>
</template>

<script lang="ts" setup>
  import { callOnce } from '#imports';
  import { useCampaignStore } from '@/stores/campaign';

  const { init } = useCampaignStore();

  useState('loading', () => false);

  const user = useAuthUser().value;
  const { locale } = useI18n();

  locale.value = user?.language || 'pt_BR';

  await callOnce(init);
</script>
